import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AuthService from "../../../services/Auth/Auth";
import FontAwesome from "../FontAwesome";
import CSS from "./Nav.module.css";
import CSSItem from "./NavItem.module.css";
import NavItem from "./NavItem";

const Nav = () => {
  const [navState, setNavState] = useState(false);
  const [navHideState, setNavHideState] = useState(false);
  const authService = useContext(AuthService);
  const location = useLocation();

  useEffect(() => {
    closeMenu();
  }, [location]);

  useEffect(()=>{
    const classList = document.body.classList;
    const className = 'nav_hidden';

    if(navHideState){
        classList.add(className);
    } else {
        classList.remove(className);        
    }

  }, [navHideState]);

  function closeMenu() {
    setNavState(false);
  }

  function openMenu() {
    setNavState(true);
  }

  function toogleHide(){
    setNavHideState(!navHideState);
  }

  return (
    <div className={CSS.nav_container}>
      <div className="d-lg-none">
        <div className="row align-items-center mx-0 py-2">
          <div className="col">
            <img
              alt="logo"
              src="img/logo/logo-full-small.png"
              className={CSS.logo_mobile}
            />
          </div>
          <div className="col-auto py-2 ">
            <div
              className="btn btn-light text-success py-1 px-2 rounded-pill fsc-2"
              onClick={openMenu}
            >
              <FontAwesome type="solid" icon="bars" />
            </div>
          </div>
        </div>
      </div>

      <nav
        className={
          CSS.nav + " " + (navState && CSS.nav_show) + " " + (navHideState && CSS.hidden)
        }
      >
        <div className="d-lg-none text-end p-3 text-white fsc-2">
          <div onClick={closeMenu}>
            <FontAwesome type="solid" icon="xmark" />
          </div>
        </div>

        <div className="px-5 pb-3 pt-lg-5 nav_hide_none">
          <div className="px-3 text-center">
            <a href="/" target="_blank" rel="noreferrer">
              <img
                alt="logo"
                src="img/logo/logo-full-medium_white.png"
                className={"w-100 " + CSS.logo_menu}
              />
            </a>
          </div>
        </div>

        <div className="nav_hide_block d-none px-2 py-3 mt-5 bg-light mx-2 rounded rounded-pill">
            <img
                alt="logo"
                src="img/logo/logo-full-medium_white.png"
                className="w-100 "
            />
        </div>

        <div
          className="text-uppercase text-center text-white fw-semibold nav_hide_none"
          style={{ opacity: 0.7 }}
        >
          Painel Administrativo
        </div>

        <div className={CSS.nav_buttons + " mt-5 "}>
          <NavItem fa={{ type: "solid", icon: "receipt" }} to="/pedidos">
            Pedidos
          </NavItem>

          <NavItem fa={{ type: "solid", icon: "table-list" }} to="/categorias">
            Categorias
          </NavItem>

          <NavItem fa={{ type: "solid", icon: "pizza-slice" }} to="/produtos">
            Produtos
          </NavItem>

          <NavItem fa={{ type: "solid", icon: "users" }} to="/clientes">
            Clientes
          </NavItem>
          
          <NavItem fa={{ type: "solid", icon: "ticket" }} to="/cupons">
            Cupons
          </NavItem>

          <NavItem fa={{ type: "solid", icon: "cogs" }} to="/configuracoes">
            Configurações
          </NavItem>

          <NavItem fa={{ type: "solid", icon: "lock" }} to="/acesso">
            Acesso ao Painel
          </NavItem>

          <div className={"cursor-pointer " + CSSItem.nav_item}>
            <div className="text-white px-3 py-3" onClick={authService.logout}>
              <div className="row mx-0">
                <div className="col-2">
                  <FontAwesome
                    type="solid"
                    icon="right-from-bracket"
                  ></FontAwesome>
                </div>
                <div
                  className="col-10 text-uppercase fw-bold nav_hide_none"
                  style={{ letterSpacing: "1px" }}
                >
                  Sair
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={CSS.nav_toggle + " d-none d-lg-block border-top opacity-6 cursor-pointer"}
          onClick={toogleHide}
        >
          <div className="px-4 py-2 text-white fsc-1p5">
            <div className={CSS.nav_hide_button}>
              <FontAwesome type="solid" icon="chevron-left" />
            </div>
            <div className={CSS.nav_show_button}>
              <FontAwesome type="solid" icon="chevron-right" />
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Nav;
