import { useContext, useRef, useState } from "react";
import If from "../../components/shared/Facilitators/If/If";
import ButtonLoading from "../../components/Layout/ButtonLoading";
import FontAwesome from "../../components/Layout/FontAwesome";
import PassInput from "../../components/Layout/Forms/PassInput";
import http from "../../misc/classes/HttpRequest/HttpRequest";
import AuthService from "../../services/Auth/Auth";

const Acesso = () => {
    const formRef = useRef();
    const btnSaveRef = useRef();
    const [changedPass, setChangedPass] = useState(null);
    const authService = useContext(AuthService);

    function onSubmit(e){
        e.preventDefault();
        
        const formData = new FormData(formRef.current);
        
        btnSaveRef.current.setLoading(true);

        http.put("/auth/user", formData, onSuccess, onError);
    }

    function onSuccess(data){
        btnSaveRef.current.setLoading(false);
        setChangedPass(true);
    }

    function onError(error){
        btnSaveRef.current.setLoading(false);
        setChangedPass(false);
    }

  return (
    <>
      <h1>Acesso ao Painel</h1>

      <div className="py-2"></div>

      <form className="box-widget" onSubmit={onSubmit} ref={formRef}>
        <div className="row">
            <div className="col-lg-2 col-12 fw-bold">
                Usuário:
            </div>
            <div className="col-lg-auto col">
                <input className="form-control" name="username" readOnly value={authService.username} />
            </div>
        </div>
        
        <div className="row mt-3">
            <div className="col-lg-2 col-12 fw-bold">
                Senha Atual:
            </div>
            <div className="col-lg-auto col">
                <PassInput name='password' required className={changedPass === false && 'is-invalid'} />
            </div>
        </div>
        
        <div className="row mt-3">
            <div className="col-lg-2 col-12 fw-bold">
                Nova Senha:
            </div>
            <div className="col-lg-auto col">
                <PassInput name='new_password' required />
            </div>
        </div>

        <If if={changedPass === true}>
            <div className="alert alert-success d-inline-block mt-4" >
                <FontAwesome type='solid' icon='check' /> Senha alterada com sucesso!
            </div>
        </If>

        <div className="text-lg-start text-end mt-lg-2 mt-4">
            <ButtonLoading ref={btnSaveRef}>
                <button className="btn btn-primary fw-bold">Alterar senha</button>
            </ButtonLoading>
        </div>
      </form>

    </>
  );
};

export default Acesso;
